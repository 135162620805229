import { type AxiosError, type AxiosResponse, isAxiosError } from "axios";
import { z } from "zod";

const cbhApiErrorSchema = z.object({
  code: z.string(),
  detail: z.string(),
  id: z.string(),
  status: z.string(),
  title: z.string(),
});

const cbhApiErrorsSchema = z.object({
  errors: z.array(cbhApiErrorSchema),
});

export type CbhApiError = z.infer<typeof cbhApiErrorSchema>;
export type CbhApiErrors = z.infer<typeof cbhApiErrorsSchema>;

export function isCbhApiAxiosError(error: unknown): error is AxiosError<CbhApiErrors> & {
  // this is safe since we assert that response is defined
  response: AxiosResponse<CbhApiErrors>;
} {
  if (!isAxiosError(error) || !error.response) {
    return false;
  }

  return cbhApiErrorsSchema.safeParse(error.response.data).success;
}
