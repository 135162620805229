import { IonPage } from "@ionic/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconButton } from "@mui/material";
import { ErrorPage } from "@src/appV2/Errors/ErrorPage";
import { SupportContext } from "@src/appV2/support/constants";

import { RootPaths } from "../App/paths";
import { AppBarHeader, PageWithHeader } from "../lib";

export function ErrorFallback() {
  return (
    <IonPage
      style={{
        maxWidth: "var(--page-max-width)",
        margin: "0 auto",
        boxShadow: "var(--page-box-shadow)",
      }}
    >
      <PageWithHeader
        containerMaxWidth="md"
        appBarHeader={
          <AppBarHeader
            title="Something went wrong"
            leftCta={
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="Go Back"
                title="Go Back"
                onClick={() => {
                  window.location.replace(RootPaths.HOME);
                }}
              >
                <ChevronLeftIcon fontSize="large" />
              </IconButton>
            }
          />
        }
      >
        <ErrorPage
          title="Oops! Something went wrong."
          description="Please go back or restart the app."
          supportContext={SupportContext.ERROR_FALLBACK_SCREEN}
        />
      </PageWithHeader>
    </IonPage>
  );
}
